<template>
  <div>
    <PagesPath :pathTitle1="'درباره ما'" :pathTo1="'about-us'"></PagesPath>
    <section class="p-3 bg-white box-shaddow20 border-radius20  container mt-4 mb-5">
      <h5 class="text-color-444 my-4 font-weight-bold">درباره ی  فرش ابراهیمی</h5>
      <p class="content-info-shop-footer fontsize-sm-13 mt-3 text-justify">
        {{ content?.settings?.site?.about_us_site }}
       
      </p>
      <!-- <p class="line-height2 text-color-666 fontsize14 text-justify">
        توسعه اینترنت, روشهای خرید ما را به کلی دگرگون کرده است. منافع موجود در خرید اینترنتی هر روز، تعداد بیشتری از مردم را به تجربه آن و ایجاد تغییر در الگوهای متداول خرید ترغیب می کند. بعد از تجربه دیجی‌کالا، خرید اینترنتی بیشتر با سرعت زندگی مدرن همگام شد و توانست خود را با روحیات و نیازهای رو به رشد مشتریان هماهنگ کند. در حالیکه مشغله های روزانه فرصت لذت بردن از اوقات فراغت را کاهش داده است، صرف زمان های طولانی برای انجام سفرهای درون شهری و خرید نیازهای روزانه معنای خود را از دست می دهد. آنچه در فرصت اوقات فراغت برای افراد در اولویت قرار می گیرد، تفریح، آموزش، ورزش، لذت بردن از علایق شخصی و رفع خستگی‏‏های روزانه است.
      </p>
      <section class="bg-color-eai d-flex flex-wrap  px-3 pt-3   my-4">
        <div v-for="option in options" class="ml-md-4 ml-3 mb-3">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.67012 1.99991H16.3401C19.7301 1.99991 22.0001 4.37991 22.0001 7.91991V16.0909C22.0001 19.6199 19.7301 21.9999 16.3401 21.9999H7.67012C4.28012 21.9999 2.00012 19.6199 2.00012 16.0909V7.91991C2.00012 4.37991 4.28012 1.99991 7.67012 1.99991ZM11.4301 14.9899L16.1801 10.2399C16.5201 9.89991 16.5201 9.34991 16.1801 8.99991C15.8401 8.65991 15.2801 8.65991 14.9401 8.99991L10.8101 13.1299L9.06012 11.3799C8.72012 11.0399 8.16012 11.0399 7.82012 11.3799C7.48012 11.7199 7.48012 12.2699 7.82012 12.6199L10.2001 14.9899C10.3701 15.1599 10.5901 15.2399 10.8101 15.2399C11.0401 15.2399 11.2601 15.1599 11.4301 14.9899Z" fill="#ccc"/>
          </svg>
          <span class="fontsize13 text-color-666 mr-1">{{ option.title }}</span>
        </div>
      </section>
      <p class="line-height2 text-color-666 fontsize14 text-justify">
        راهکارهای متنوعی که دنیای مدرن برای رفع نیازهای روزمره از جمله انتخاب و خرید کالا به افراد پیشنهاد می دهد، باعث افزایش سرعت و دقت در انجام این امور می شود. با این شرایط تازه دیگر کمتر کسی کالایی را بدون تحقیق و بررسی خریداری می کند( سبکی که به جرات می توان گفت به وسیله دیجی‌کالا مرسوم شد ) . مشتریان پیش از خرید هریک از کالاهای مورد نیاز خود، با طیف وسیعی از انتخاب ها مواجه اند و در مقابل آن، به اطلاعات دقیق تری نیازمندند. دقت در ویژگی های هر کالا و دسترسی به اطلاعات صحیح نیاز اساسی مشتریان است. امروزه روش های نوین و هوشمندانه خرید، می تواند به خوبی پاسخگوی نیازهای مشتریان باشد و همین امر موجب شده روزانه افراد بیشتری به جمع استفاده کنندگان سرویس های اینترنتی بپیوندند.
      </p> -->
<!--      <div class="buy-benefits-grid my-5">-->
<!--        <div>-->
<!--          <h5 class="mb-4">مزایای خرید</h5>-->
<!--          <div class="d-flex mb-3">-->
<!--            <div class="buy-benefits-svg rounded-circle bg-color-theme">-->
<!--              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.92574 16.39H14.3119C14.7178 16.39 15.0545 16.05 15.0545 15.64C15.0545 15.23 14.7178 14.9 14.3119 14.9H8.92574C8.5198 14.9 8.18317 15.23 8.18317 15.64C8.18317 16.05 8.5198 16.39 8.92574 16.39ZM12.2723 9.9H8.92574C8.5198 9.9 8.18317 10.24 8.18317 10.65C8.18317 11.06 8.5198 11.39 8.92574 11.39H12.2723C12.6782 11.39 13.0149 11.06 13.0149 10.65C13.0149 10.24 12.6782 9.9 12.2723 9.9ZM19.3381 9.02561C19.5708 9.02292 19.8242 9.02 20.0545 9.02C20.302 9.02 20.5 9.22 20.5 9.47V17.51C20.5 19.99 18.5099 22 16.0545 22H8.17327C5.59901 22 3.5 19.89 3.5 17.29V6.51C3.5 4.03 5.5 2 7.96535 2H13.2525C13.5099 2 13.7079 2.21 13.7079 2.46V5.68C13.7079 7.51 15.203 9.01 17.0149 9.02C17.4381 9.02 17.8112 9.02316 18.1377 9.02593C18.3917 9.02809 18.6175 9.03 18.8168 9.03C18.9578 9.03 19.1405 9.02789 19.3381 9.02561ZM19.6111 7.566C18.7972 7.569 17.8378 7.566 17.1477 7.559C16.0527 7.559 15.1507 6.648 15.1507 5.542V2.906C15.1507 2.475 15.6685 2.261 15.9646 2.572C16.5004 3.13476 17.2368 3.90834 17.9699 4.67837C18.7009 5.44632 19.4286 6.21074 19.9507 6.759C20.2398 7.062 20.0279 7.565 19.6111 7.566Z" fill="#fff"/>-->
<!--              </svg>-->
<!--            </div>-->
<!--            <div class="mr-2 align-self-center">-->
<!--              <h6 class="mb-0 text-color-444 ">شفاف سازی قیمت خدمات</h6>-->
<!--              <span class="fontsize14 text-color-666">با قیمت ثابت مشکل خود خدمت مورد آن خدمت دارای قیمت ثابت است</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="d-flex mb-3">-->
<!--            <div class="buy-benefits-svg rounded-circle bg-color-theme">-->
<!--              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.92574 16.39H14.3119C14.7178 16.39 15.0545 16.05 15.0545 15.64C15.0545 15.23 14.7178 14.9 14.3119 14.9H8.92574C8.5198 14.9 8.18317 15.23 8.18317 15.64C8.18317 16.05 8.5198 16.39 8.92574 16.39ZM12.2723 9.9H8.92574C8.5198 9.9 8.18317 10.24 8.18317 10.65C8.18317 11.06 8.5198 11.39 8.92574 11.39H12.2723C12.6782 11.39 13.0149 11.06 13.0149 10.65C13.0149 10.24 12.6782 9.9 12.2723 9.9ZM19.3381 9.02561C19.5708 9.02292 19.8242 9.02 20.0545 9.02C20.302 9.02 20.5 9.22 20.5 9.47V17.51C20.5 19.99 18.5099 22 16.0545 22H8.17327C5.59901 22 3.5 19.89 3.5 17.29V6.51C3.5 4.03 5.5 2 7.96535 2H13.2525C13.5099 2 13.7079 2.21 13.7079 2.46V5.68C13.7079 7.51 15.203 9.01 17.0149 9.02C17.4381 9.02 17.8112 9.02316 18.1377 9.02593C18.3917 9.02809 18.6175 9.03 18.8168 9.03C18.9578 9.03 19.1405 9.02789 19.3381 9.02561ZM19.6111 7.566C18.7972 7.569 17.8378 7.566 17.1477 7.559C16.0527 7.559 15.1507 6.648 15.1507 5.542V2.906C15.1507 2.475 15.6685 2.261 15.9646 2.572C16.5004 3.13476 17.2368 3.90834 17.9699 4.67837C18.7009 5.44632 19.4286 6.21074 19.9507 6.759C20.2398 7.062 20.0279 7.565 19.6111 7.566Z" fill="#fff"/>-->
<!--              </svg>-->
<!--            </div>-->
<!--            <div class="mr-2 align-self-center">-->
<!--              <h6 class="mb-0 text-color-444 ">شفاف سازی قیمت خدمات</h6>-->
<!--              <span class="fontsize14 text-color-666">با قیمت ثابت مشکل خود خدمت مورد آن خدمت دارای قیمت ثابت است</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="d-flex mb-3">-->
<!--            <div class="buy-benefits-svg rounded-circle bg-color-theme">-->
<!--              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.92574 16.39H14.3119C14.7178 16.39 15.0545 16.05 15.0545 15.64C15.0545 15.23 14.7178 14.9 14.3119 14.9H8.92574C8.5198 14.9 8.18317 15.23 8.18317 15.64C8.18317 16.05 8.5198 16.39 8.92574 16.39ZM12.2723 9.9H8.92574C8.5198 9.9 8.18317 10.24 8.18317 10.65C8.18317 11.06 8.5198 11.39 8.92574 11.39H12.2723C12.6782 11.39 13.0149 11.06 13.0149 10.65C13.0149 10.24 12.6782 9.9 12.2723 9.9ZM19.3381 9.02561C19.5708 9.02292 19.8242 9.02 20.0545 9.02C20.302 9.02 20.5 9.22 20.5 9.47V17.51C20.5 19.99 18.5099 22 16.0545 22H8.17327C5.59901 22 3.5 19.89 3.5 17.29V6.51C3.5 4.03 5.5 2 7.96535 2H13.2525C13.5099 2 13.7079 2.21 13.7079 2.46V5.68C13.7079 7.51 15.203 9.01 17.0149 9.02C17.4381 9.02 17.8112 9.02316 18.1377 9.02593C18.3917 9.02809 18.6175 9.03 18.8168 9.03C18.9578 9.03 19.1405 9.02789 19.3381 9.02561ZM19.6111 7.566C18.7972 7.569 17.8378 7.566 17.1477 7.559C16.0527 7.559 15.1507 6.648 15.1507 5.542V2.906C15.1507 2.475 15.6685 2.261 15.9646 2.572C16.5004 3.13476 17.2368 3.90834 17.9699 4.67837C18.7009 5.44632 19.4286 6.21074 19.9507 6.759C20.2398 7.062 20.0279 7.565 19.6111 7.566Z" fill="#fff"/>-->
<!--              </svg>-->
<!--            </div>-->
<!--            <div class="mr-2 align-self-center">-->
<!--              <h6 class="mb-0 text-color-444 ">شفاف سازی قیمت خدمات</h6>-->
<!--              <span class="fontsize14 text-color-666">با قیمت ثابت مشکل خود خدمت مورد آن خدمت دارای قیمت ثابت است</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="d-flex mb-3">-->
<!--            <div class="buy-benefits-svg rounded-circle bg-color-theme">-->
<!--              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.92574 16.39H14.3119C14.7178 16.39 15.0545 16.05 15.0545 15.64C15.0545 15.23 14.7178 14.9 14.3119 14.9H8.92574C8.5198 14.9 8.18317 15.23 8.18317 15.64C8.18317 16.05 8.5198 16.39 8.92574 16.39ZM12.2723 9.9H8.92574C8.5198 9.9 8.18317 10.24 8.18317 10.65C8.18317 11.06 8.5198 11.39 8.92574 11.39H12.2723C12.6782 11.39 13.0149 11.06 13.0149 10.65C13.0149 10.24 12.6782 9.9 12.2723 9.9ZM19.3381 9.02561C19.5708 9.02292 19.8242 9.02 20.0545 9.02C20.302 9.02 20.5 9.22 20.5 9.47V17.51C20.5 19.99 18.5099 22 16.0545 22H8.17327C5.59901 22 3.5 19.89 3.5 17.29V6.51C3.5 4.03 5.5 2 7.96535 2H13.2525C13.5099 2 13.7079 2.21 13.7079 2.46V5.68C13.7079 7.51 15.203 9.01 17.0149 9.02C17.4381 9.02 17.8112 9.02316 18.1377 9.02593C18.3917 9.02809 18.6175 9.03 18.8168 9.03C18.9578 9.03 19.1405 9.02789 19.3381 9.02561ZM19.6111 7.566C18.7972 7.569 17.8378 7.566 17.1477 7.559C16.0527 7.559 15.1507 6.648 15.1507 5.542V2.906C15.1507 2.475 15.6685 2.261 15.9646 2.572C16.5004 3.13476 17.2368 3.90834 17.9699 4.67837C18.7009 5.44632 19.4286 6.21074 19.9507 6.759C20.2398 7.062 20.0279 7.565 19.6111 7.566Z" fill="#fff"/>-->
<!--              </svg>-->
<!--            </div>-->
<!--            <div class="mr-2 align-self-center">-->
<!--              <h6 class="mb-0 text-color-444 ">شفاف سازی قیمت خدمات</h6>-->
<!--              <span class="fontsize14 text-color-666">با قیمت ثابت مشکل خود خدمت مورد آن خدمت دارای قیمت ثابت است</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="buy-benefits-img">-->
<!--          <div class="  mb-2"><img src="../../../../src/assets/images/insta1.jpg" alt=""></div>-->
<!--          <div class=" "><img src="../../../../src/assets/images/insta5.jpg" alt=""></div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <p class="line-height2 text-color-666 fontsize14 text-justify">-->
<!--        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.-->
<!--      </p>-->

<!--      <div class="about-us-why mt-4">-->
<!--        <div class="about-us-why-img">-->
<!--          <img src="../../../../src/assets/images/product1.png" alt="">-->
<!--        </div>-->
<!--        <div>-->
<!--          <h6>چرا اطلس ... ؟</h6>-->
<!--          <p class="line-height2 text-color-666 fontsize14 text-justify">-->
<!--            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->
    </section>

    <Footer></Footer>
  </div>
</template>

<script>
import PagesPath from "@/parts/Front/components/PagesPath";
export default {
  name: "AboutUs",
  components:{
    PagesPath
  },
  data() {
    return {
      options: [
        {
          'title': 'رنگبندی متفاوت'
        },
        {
          'title': 'قیمت مناسب'
        },
        {
          'title': 'کیفیت خوب'
        }
      ]
    }
  },
  mounted() {
    let aboutUsImg=document.querySelectorAll('.about-us-why-img')[0];
    aboutUsImg.style.height = (aboutUsImg.offsetWidth * 1.2) + "px";
    window.addEventListener('resize' , function() {
      let aboutUsImg=document.querySelectorAll('.about-us-why-img')[0];
      aboutUsImg.style.height = (aboutUsImg.offsetWidth * 1.2) + "px";
    });
  },
  computed: {
    // دریافت محتوای درخواست هوم
    content() {
      console.log(this.$store.getters["front/getHomeData"]);
      return this.$store.getters["front/getHomeData"];
    },
    loading() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
}
</script>

<style scoped>

</style>
